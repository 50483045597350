<template>
  <ctk-list-filters
    v-model="selectedFilters"
    :label="$t('app.labels.filter')"
    :dialog-title="$t('app.titles.filters')"
    :filters="filters"
    :has-dot="hasDot"
    :multiple="false"
    class="proposals-list-filters"
    @input="$emit('update')"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import CtkListFilters from '@/components/CtkListFilters/index.vue'

  /**
   * @module component - ProposalsListFilters
   */
  export default defineComponent({
    name: 'ProposalsListFilters',
    components: {
      CtkListFilters
    },
    data () {
      return {
        filters: {
          all: this.$t('offer.proposals.filters.all'),
          pending: this.$t('offer.proposals.filters.pending'),
          accepted: this.$t('offer.proposals.filters.accepted'),
          renewable: this.$t('offer.proposals.filters.renewable'),
          closed: this.$t('offer.proposals.filters.closed')
        }
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getProposalsFilter'
      ]),
      hasDot () {
        return !this.selectedFilters.map(filter => filter.value).includes('all')
      },
      selectedFilters: {
        get () {
          const filter = this.getProposalsFilter
          return [
            {
              value: filter,
              text: this.filters[filter]
            }
          ]
        },
        set (v) {
          this.setProposalsFilter({
            filter: v[0].value
          })
        }
      }
    },
    methods: {
      ...mapActions('offers', [
        'setProposalsFilter'
      ])
    }
  })
</script>

<style lang="scss">
.proposals-list-filters .ui-select-button-button {
  margin-top: 0.5rem;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
