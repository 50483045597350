<template>
  <div
    class="ui-select-button-list"
    v-click-outside="clickOutside"
  >
    <ul
      :aria-hidden="false"
      :aria-labelledby="id"
      :aria-activedescendant="activeItems.length > 0
        ? `${id}-item-${activeItems[0].value}`
        : null"
      ref="list"
      role="listbox"
      tabindex="-1"
      class="ui-select-button-list__el"
      @focus="setupFocus"
      @blur="!multiple && hide"
      @keydown="checkHide"
    >
      <ui-select-button-list-item
        v-for="item in items"
        :key="item.value"
        :id="id"
        :item="item"
        :selected="isActive(item)"
        @click.native.stop="click(item)"
      >
        <template
          v-if="multiple"
          #item="{ item: scopeItem }"
        >
          <div
            class="tw-flex tw-items-center"
          >
            <ui-material-icon
              :name="isActive(scopeItem)
                ? 'check_box'
                : 'check_box_outline_blank'"
              class="tw-mr-2"
            />
            {{ item.text }}
          </div>
        </template>
      </ui-select-button-list-item>
    </ul>
    <div
      v-if="multiple"
      class="p-2"
    >
      <ui-button
        variant="info"
        size="sm"
        class="tw-w-full"
        @click="validate()"
      >
        {{ $t('validate') | capitalize }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { directive } from 'v-click-outside'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import { KEYCODES } from '@/composables/constants'
  import UiSelectButtonListItem from './_subs/UiSelectButtonListItem/index.vue'

  /**
   * @module component - UiSelectButtonList
   * @param {string} id
   * @param {Array<any>} items
   * @param {boolean} [multiple=false]
   */
  export default defineComponent({
    name: 'UiSelectButtonList',
    directives: {
      clickOutside: directive
    },
    components: {
      UiSelectButtonListItem
    },
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      value: {
        type: [Array],
        default: null
      },
      open: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: isOpen } = useModelGetterSetter(props, 'open')

      return {
        isOpen
      }
    },
    data () {
      return {
        keysSoFar: '',
        keyClear: null,
        activeItems: []
      }
    },
    mounted () {
      /**
       * Set the current active item from the current value.
       */
      if (this.multiple) {
        this.activeItems = this.items
          .filter(item => this.value.map(v => v.value).includes(item.value))
      } else {
        if (!this.value) return

        const activeItem = this.items.find(item => item.value === (this.value[0] && this.value[0].value))
        if (activeItem) this.activeItems = [activeItem]
      }
    },
    methods: {
      isActive (item) {
        return this.activeItems.map(v => v.value).includes(item.value)
      },
      /**
       * Called whenever the users clicks outside the list
       * Should trigger the hide function if it's a multiple select
       * @function clickOutside
       */
      clickOutside () {
        this.hide()
      },
      validate (item) {
        if (this.multiple) {
          this.$emit('input', this.activeItems)
        } else {
          this.$emit('input', [item || this.value])
        }
      },
      /**
       * @function click
       * @param {Object} item
       */
      click (item) {
        if (!this.multiple) {
          this.hide()
          this.selectValue(item)
        } else {
          this.clickActive(item)
        }
      },
      /**
       * From a multi select perspective, able to deselect a item
       * from the active items list.
       * @function deselectValue
       */
      deselectValue (item) {
        const itemIndex = this.activeItems.findIndex(v => v.value === item.value)
        if (itemIndex !== -1) {
          this.activeItems.splice(itemIndex, 1)
        }
      },
      clickActive (item) {
        if (this.multiple) {
          if (this.isActive(item)) this.deselectValue(item)
          else this.activeItems.push(item)
        } else {
          this.activeItems = [item]
        }
      },
      /**
       * @function selectValue
       * @param {object} item
       */
      selectValue (item) {
        this.clickActive(item)
        this.validate(item)
      },
      focus () {
        this.$refs.list.focus()
      },
      /**
       * If there is no active descendent, focus the first element
       * @function setupFocus
       */
      setupFocus () {
        const list = this.$refs.list
        if (!list) return

        // @ts-ignore
        const activeDescendant = list.getAttribute('aria-activedescendant')
        if (activeDescendant) {
          return
        }
        if (!this.multiple) this.focusFirstItem()
      },
      hide () {
        this.isOpen = false
      },
      checkHide (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.ESC:
          e.preventDefault()
          this.hide()
          this.$emit('re-focus')
          break
        }
        this.keyPress(e)
      },
      focusItem (element) {
        if (!element) return

        const value = element.getAttribute('data-item-value')
        if (value) {
          const itemList = this.items.find(item => item.value.toString() === value.toString())
          if (itemList) this.activeItems = [itemList]
        }
        const list = this.$refs.list
        if (list.scrollHeight > list.clientHeight) {
          const scrollBottom = list.clientHeight + list.scrollTop
          const elementBottom = element.offsetTop + element.offsetHeight
          if (elementBottom > scrollBottom) {
            list.scrollTop = elementBottom - list.clientHeight
          } else if (element.offsetTop < list.scrollTop) {
            list.scrollTop = element.offsetTop
          }
        }
      },
      focusLastItem () {
        const list = this.$refs.list
        const itemList = list.querySelectorAll('[role="option"]')
        if (itemList.length) {
          this.focusItem(itemList[itemList.length - 1])
        }
      },
      focusFirstItem () {
        const list = this.$refs.list
        const firstItem = list.querySelector('[role="option"]')
        if (firstItem) {
          this.focusItem(firstItem)
        }
      },
      toggleSelectItem () {
        const item = this.items
          .find(item => item.value.toString() === this.activeItems[0].value.toString())

        this.selectValue(item)
      },
      clearKeysSoFarAfterDelay () {
        if (this.keyClear) {
          clearTimeout(this.keyClear)
          this.keyClear = null
        }
        this.keyClear = setTimeout(() => {
          this.keysSoFar = ''
          this.keyClear = null
        }, 500)
      },
      findItem (key) {
        const list = this.$refs.list
        if (!list) return false

        const itemList = list.querySelectorAll('[role="option"]')
        const activeDescendant = list.getAttribute('aria-activedescendant')
        const character = String.fromCharCode(key)
        let searchIndex = 0
        if (!this.keysSoFar) {
          for (var i = 0; i < itemList.length; i++) {
            if (itemList[i].getAttribute('id') === activeDescendant) {
              searchIndex = i
            }
          }
        }
        this.keysSoFar += character
        this.clearKeysSoFarAfterDelay()
        let nextMatch = this.findMatchInRange(itemList, searchIndex + 1, itemList.length)
        if (!nextMatch) {
          nextMatch = this.findMatchInRange(itemList, 0, searchIndex)
        }
        return nextMatch
      },
      findMatchInRange (list, startIndex, endIndex) {
        // Find the first item starting with the keysSoFar substring, searching in
        // the specified range of items
        for (let n = startIndex; n < endIndex; n++) {
          const label = list[n].innerText
          if (label && label.toUpperCase().indexOf(this.keysSoFar) === 0) {
            return list[n]
          }
        }
        return null
      },
      async keyPress (e) {
        const key = e.which || e.keyCode
        const list = this.$refs.list
        if (!list) return

        await this.$nextTick()
        const activeDescendant = list.getAttribute('aria-activedescendant')
        let nextItem = document.getElementById(activeDescendant)
        if (!nextItem) {
          return
        }
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          if (key === KEYCODES.UP) {
            nextItem = nextItem.previousElementSibling
          } else {
            nextItem = nextItem.nextElementSibling
          }
          if (nextItem) {
            this.focusItem(nextItem)
          }
          break
        case KEYCODES.HOME:
          e.preventDefault()
          this.focusFirstItem()
          break
        case KEYCODES.END:
          e.preventDefault()
          this.focusLastItem()
          break
        case KEYCODES.SPACE:
        case KEYCODES.RETURN:
          e.preventDefault()
          this.toggleSelectItem(nextItem)
          this.hide()
          this.$emit('re-focus')
          break
        default: {
          const item = this.findItem(key)
          if (item) {
            this.focusItem(item)
          }
          break
        }
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ui-select-button-list {
    position: absolute;
    list-style: none;
    background: #FFF;
    z-index: 12;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

    &__el {
      padding: 0;
      margin: 0;
      max-height: 200px;
      overflow: auto;

      &:focus {
        outline: none;
      }
    }
  }

</style>
