<template>
  <empty-state
    :title="$t('offer_list.main_content.no_proposal_available')"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import EmptyState from '@/views/Carriers/Offers/components/EmptyState/index.vue'

  export default defineComponent({
    components: {
      EmptyState
    }
  })
</script>
