<template>
  <ctk-list-header
    :title="$t($route.name === 'Proposals'
      ? 'offer.proposals.filters.all'
      : 'offer.proposals.filters.all_pending')"
    :subtitle="$tc('proposal_list.subtitle', getProposalsMeta.item_count, {
      count: $n(getProposalsMeta.item_count)
    })"
    class="flex-fixed ctk-proposals-list__header tw-px-4 tw-flex-shrink-0"
  >
    <proposals-list-filters
      v-if="$route.name === 'Proposals'"
      data-test="filters"
      @update="retrieveProposals"
    />
  </ctk-list-header>
</template>

<script>
  import { computed, defineComponent } from '@vue/composition-api'

  import ProposalsListFilters from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalsListFilters/index.vue'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'

  import useWait from '@/composables/useWait'
  import useStore from '@/composables/useStore'

  /**
   * @module component - ProposalsListHeader
   */
  export default defineComponent({
    name: 'ProposalsListHeader',
    components: {
      ProposalsListFilters,
      CtkListHeader
    },
    setup () {
      const wait = useWait()
      const store = useStore()

      const getProposalsMeta = computed(() => store.value.getters['offers/getProposalsMeta'])

      function retrieveProposals () {
        wait.start('fetching proposals')
        Promise.all([
          store.value.dispatch('offers/retrieveProposalsMetrics'),
          store.value.dispatch('offers/retrieveProposalsByStatus', {})
        ])
          .catch(() => {})
          .finally(() => {
            wait.end('fetching proposals')
          })
      }

      return {
        retrieveProposals,
        getProposalsMeta
      }
    }
  })
</script>
