<template>
  <div class="load-layer offers-list-loading">
    <div class="load-layer tw-flex-col">
      <ui-loader
        :size="50"
        background-back="#000"
        class="mb-4"
        type="pallet"
      />
      <p class="fs-16 tw-text-gray-700">
        {{ title | capitalize }}
      </p>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'OffersListLoading',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>
