<template>
  <offers-aside-layout
    id="proposals"
    ref="proposals"
    class="proposals"
  >
    <template
      v-slot:header
    >
      <proposals-list-header />
    </template>

    <proposals-list
      v-if="getProposals.length"
      class="tw-min-h-0"
      data-test="list"
    />

    <proposals-empty-state
      v-else
      :title="$t('offer_list.main_content.no_proposal_available')"
      data-test="empty"
    />
  </offers-aside-layout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import OffersAsideLayout from '@/views/Carriers/Offers/components/OffersAsideLayout/index.vue'
  import ProposalsListHeader from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalsListHeader/index.vue'
  import ProposalsList from '@/views/Carriers/Offers/components/ProposalsList/index.vue'
  import ProposalsListFilters from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalsListFilters/index.vue'
  import ProposalsEmptyState from '@/views/Carriers/Offers/components/ProposalsEmptyState/index.vue'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'

  import store from '@/store'

  export default defineComponent({
    name: 'Proposals',
    metaInfo () {
      return {
        title: this.$t('proposals.title')
      }
    },
    components: {
      OffersAsideLayout,
      ProposalsList,
      ProposalsListHeader,
      ProposalsListFilters,
      ProposalsEmptyState,
      CtkListHeader
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      const state = to.params.state || null

      store.dispatch('wait/start', 'fetching proposals', { root: true })
      Promise.all([
        store.dispatch('offers/retrieveProposalsMetrics', { root: true }),
        store.dispatch('offers/retrieveProposalsByStatus', {
          state
        }, { root: true })
      ])
        .catch(() => {})
        .finally(() => {
          store.dispatch('wait/end', 'fetching proposals', { root: true })
          store.dispatch('setAppReady', true, { root: true })

          next()
        })
    },
    // @ts-ignore
    beforeRouteUpdate (to, from, next) {
      store.dispatch('offers/resetCurrentProposal')
      store.dispatch('offers/resetCurrentOffer')
      next()
    },
    computed: {
      ...mapGetters('offers', [
        'getProposals'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .proposals {
    .load-layer {
      z-index: 2;
    }

    .ctk-proposals-list__header {
      &::after {
        left: 1rem;
        width: calc(100% - 2rem);
      }
    }
  }

</style>
