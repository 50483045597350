<template>
  <div class="shipment-item-proposals-item-group">
    <div class="fs-13 shipment-item-proposals-item-group__label">
      {{ label }}
    </div>
    <div
      :class="{
        'tw-font-medium': highlighted
      }"
      class="tw-text-blue-500"
      data-test="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
 /**
  * @module component - shipmentItemProposalsItemGroup
  * @param {string} label
  * @param {boolean} [highlighted=false]
  */
  export default {
    name: 'ShipmentItemProposalsItemGroup',
    props: {
      label: {
        type: String,
        required: true
      },
      highlighted: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-proposals-item-group {
    position: relative;

    &__label {
      color: $secondary-text;
    }

    &::after {
      position: absolute;
      content: '';
      width: 7px;
      height: 20px;
      top: 10px;
      left: -16px;
      border: 1px solid $third-text;
      border-right: none;
    }
  }

</style>
