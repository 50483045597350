<template>
  <div class="tw-flex shipment-item-proposals-item-dates-price">
    <shipment-item-proposals-item-group
      :label="$t('app.labels.pickup')"
      :highlighted="highlighted"
      class="tw-flex-1"
      data-test="pickup"
    >
      {{ proposal.pickup_date
        ? $moment(proposal.pickup_date).format('LL')
        : $t('app.values.proposals.same_dates') }}
    </shipment-item-proposals-item-group>

    <shipment-item-proposals-item-group
      :label="$t('app.labels.delivery')"
      :highlighted="highlighted"
      class="tw-flex-1"
      data-test="delivery"
    >
      {{ proposal.delivery_date
        ? $moment(proposal.delivery_date).format('LL')
        : $t('app.values.proposals.same_dates') }}
    </shipment-item-proposals-item-group>

    <shipment-item-proposals-item-group
      :label="$t('app.labels.proposals.price')"
      :highlighted="highlighted"
      class="tw-flex-1"
      data-test="price"
    >
      {{ price
        ? $t('price_excl_tax', {
          price: $options.filters.currency(price, currency, $i18n.locale)
        })
        : $t('app.values.proposals.same_price') }}
    </shipment-item-proposals-item-group>
  </div>
</template>

<script>
  import ShipmentItemProposalsItemGroup from './../ShipmentItemProposalsItemGroup'

  /**
   * @module component - shipmentItemProposalsItemDatesPrice
   * @param {object} proposal
   * @param {string} currency
   * @param {boolean} highlighted
   */
  export default {
    name: 'ShipmentItemProposalsItemDatesPrice',
    components: {
      ShipmentItemProposalsItemGroup
    },
    props: {
      proposal: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      highlighted: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      price () {
        return this.proposal.shipper_price || this.proposal.price
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-proposals-item-dates-price {
    @media only screen and (max-width: 620px) {
      flex-direction: column;
    }
  }

</style>
