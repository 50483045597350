<template>
  <li
    class="ui-select-button-list-item"
    :class="{
      selected
    }"
    :aria-selected="selected"
    :id="`${id}-item-${item.value}`"
    :data-item-value="item.value"
    role="option"
  >
    <slot
      :item="item"
      name="item"
    >
      {{ item.text }}
    </slot>
  </li>
</template>

<script>
  /**
   * @module component - UiSelectButtonListItem
   * @param {string} id
   * @param {object} item
   * @param {boolean} selected
   */
  export default {
    name: 'UiSelectButtonListItem',
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      item: {
        type: Object,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ui-select-button-list-item {
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    text-align: left;
    color: $primary-text;

    &:hover {
      background-color: #F2F2F2;
    }

    &.selected {
      color: $info;
    }
  }

</style>
