<template>
  <triangle-popover
    :id="`proposal-${proposal.uuid}`"
    :class="proposalClass"
    class="initial-offer-triangle"
    :small="small"
  >
    <div
      class="tw-mb-1 tw-font-medium"
      v-text="$t('offer.proposals.title.initial_offer')"
      data-test="popover-title"
    />

    <div
      v-if="price"
      data-test="price"
    >
      <span
        v-text="$options.filters.capitalize($t('price'))"
        data-test="label"
      />
      &nbsp;
      <span
        class="tw-font-medium"
        v-text="price"
        data-test="value"
      />
    </div>
    <div
      v-if="proposal.offer.pickup.time_slot.date"
      class="tw-mb-0"
      data-test="pickup"
    >
      <span
        v-text="$options.filters.capitalize($t('app.labels.pickup'))"
        data-test="label"
      />
      <span
        class="tw-font-medium"
        data-test="value"
      >
        {{ proposal.offer.pickup.time_slot.date | moment('ll') }}
      </span>
    </div>
    <div
      v-if="proposal.offer.delivery.time_slot.date"
      class="tw-mb-2"
      data-test="delivery"
    >
      <span
        v-text="$options.filters.capitalize($t('app.labels.delivery'))"
        data-test="label"
      />
      <span
        class="tw-font-medium"
        data-test="value"
      >
        {{ proposal.offer.delivery.time_slot.date | moment('ll') }}
      </span>
    </div>
    <span
      :class="`label-state label-state-${proposalClass}`"
      v-text="$options.filters.capitalize($tc(`proposal_state.${proposal.status}`, 1))"
      data-test="state"
    />
  </triangle-popover>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import TrianglePopover from '@/views/Carriers/Offers/components/OffersList/_subs/TrianglePopover/index.vue'

  import useCurrency from '@/composables/useCurrency'
  import useI18n from '@/composables/useI18n'

  /**
   * @module component - InitialOfferTriangle
   * @param {object} proposal
   * @param {string} currency
   * @param {boolean} [small=false]
   */
  export default defineComponent({
    name: 'InitialOfferTriangle',
    components: {
      TrianglePopover
    },
    props: {
      proposal: {
        type: Object,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      small: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const i18n = useI18n()

      const { proposal, currency } = toRefs(props)

      const price = computed(() => {
        const { price, carrier_price: carrierPrice } = proposal.value.offer.pricing

        return price || carrierPrice
          ? useCurrency(price || carrierPrice, currency.value, i18n.value.locale)
          : null
      })

      const proposalClass = computed(() => {
        const { status } = proposal.value
        const danger = ['expired', 'cancelled', 'revoked', 'refused']

        return danger.includes(status) ? 'danger' : status
      })

      return {
        price,
        proposalClass
      }
    }
  })
</script>

<style lang="scss" scoped>

  .initial-offer-triangle {
    &.accepted::after {
      border-color: transparent transparent transparent $proposal-accepted;
    }

    &.pending::after {
      border-color: transparent transparent transparent $proposal-pending;
    }

    &.danger::after {
      border-color: transparent transparent transparent $proposal-danger;
    }
  }

</style>
